<template>
  <div class="mine">
    <div class="container">
      <a-row :gutter="[{ xs: 0, md: 10,lg: 20},{ xs: 0, md: 10,lg: 20}]">
        <a-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          <ul class="side-nav">
            <li
            @click="handleRouterClick(item)"
            v-for="item in routerLinks" :key="item.url" 
            :class="{'link-active':$route.name === item.url}"
            >
              <span>{{item.name}}</span>
            </li>
            <li @click="handeImagePreview('ApplicableDeviceVisible')">{{$t("route.device")}}</li>
            <li @click="handeImagePreview('ActivateStepVisible')">{{$t("route.guide")}}</li>
            <li @click="onLogoutHandler">{{$t("route.logout")}}</li>
          </ul>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
          <router-view class="router-view"></router-view>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import { ApplicableDeviceImagePreview,ActivateStepImagePreview } from '../utils/imagePreview'
export default {
  name: "Mine",
  data() {
    return {
      commonLinks: [
        { name: this.$t("route.user") ,url:'User' },
        { name: this.$t("route.order") ,url:'Order'},
        { name: this.$t("route.flow") ,url:'Esims'},
        { name: this.$t("route.coupon") ,url:'Coupon'},
        { name: this.$t("route.invite") ,url:'Invite'},
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isSharer','isWallet']),
    routerLinks() {
      const links = [...this.commonLinks];
      if (this.isSharer) {
        links.splice(0, 0, { name: this.$t('route.share'), url: 'Share' });
      }
      if (this.isWallet) {
        links.splice(2, 0, { name: this.$t('route.wallet'), url: 'Wallet' });
      }
      return links;
    }
  },
  mounted(){
  },
  methods:{
    ...mapActions('user',['Logout']),
    handleRouterClick({url}){
      this.$router.push({ name: url });
    },
    async onLogoutHandler(){
      await this.$dialog.confirm({
        title: this.$t('login.logout_title'),
        message: this.$t('login.logout_text'),
        confirmButtonText: this.$t('login.logout_ok'),
        cancelButtonText: this.$t('common.cancel'),
      })
      await this.Logout()
      const { requiresAuth } = this.$route.meta
      if(requiresAuth) this.$router.push({ name: 'Home' });
    },
    handeImagePreview(visible){
      if (visible === 'ActivateStepVisible') {
        const locale = this.$i18n.locale
        const lang = locale == 'zh-cn' ? 'zh-cn' : locale == 'zh-tw' ? 'hk-tw' : 'en'
        ActivateStepImagePreview(lang)
        return 
      }else{
        ApplicableDeviceImagePreview()
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.mine{
  .router-view{
    border-radius: 6px;
    padding: 16px;
    margin: 20px 0;
    box-shadow: 0px 0px 12px 2px rgba(48, 49, 51, 0.1);
    background-color: #fff;
  }
  .side-nav{
    display: none;
    padding: 20px 0;
    border-radius: 6px;
    box-shadow: 0px 0px 12px 2px rgba(48, 49, 51, 0.1);
    background-color: #fff;
    li{
      color: #222222;
      text-align: center;
      user-select: none;
      cursor: pointer;
      padding: 20px 10px;
      position: relative;
      &::after{
        content:"";
        width: 0;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        border-radius: 24px;
        background-color: $color;
        transform: translateX(-50%);
        transition: all .4s cubic-bezier(0.4,0.8,0.74,1);
      }
      &:hover{
        transition: all .3s cubic-bezier(0.4,0.8,0.74,1);
        &::after{
          width: 20%;
        }
      }
    }
    .link-active{
      position: relative;
      font-weight: 600;
      &::after{
        width: 12%;
      }
    }
  }
  @media(min-width:992px){
    padding: 40px 0;
    .side-nav{
      display: block;
    }
    .router-view{
      padding: 20px;
      margin: 0;
      box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
    }
  }
}

</style>
